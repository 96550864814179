<template>
	<div class="farming-pool">
		<div class="top">
			<div class="top-content">
				<div class="top-left">
					<p class="title">{{ $t('Farming Pool') }}</p>
					<p class="subtitle">{{ $t('Combine NFT cards to farm every seconds') }}</p>
					<p class="total">
						<img src="@/assets/BUSD.png" alt="">
						<span>{{ Number(poolAmt).toFixed(4) }}</span>
					</p>
					<a :href="`https://bscscan.com/address/${dividend_address}`" target="_blank">{{ $t('Smart Contract') }}</a>
				</div>
				<div class="top-content_img">
					<img class="top-img" src="@/assets/faramingpool01.png" alt="">
				</div>
				<div class="top-balance__box">
					<div class="right">
						<p class="balance-title">{{ $t('Your Balance') }}</p>
						<p class="balance-value">
							<img src="@/assets/BUSD.png" alt="">
							<span>{{ Number(balance).toFixed(4) }}</span>
						</p>
					</div>
					<v-btn
							:style="is_mobile ? mobile_styles : pc_styles"
							color="#EB6930"
							large
							rounded
							:loading="btn_loading"
							@click="_receiveDividendIncome"
					>{{ $t('Harvest') }}</v-btn>
				</div>
			</div>
		</div>
		<div class="total-stacking"></div>

		<div class="m-mining_staking" v-if="is_mobile">
			<div class="m-mining_staking-title">{{ $t('My Staking') }}</div>

			<div class="m-mining_staking-item" v-for="(item, index) in dividend_list" :key="index">
				<div class="m-mining_staking-index">{{ index + 1 }}</div>
				<div class="m-mining_staking-content">
					<div class="m-mining_staking-row">
						<div>{{ $t('NAME') }}</div>
						<div>{{ cards[item.key].zhHans }}</div>
					</div>
					<div class="m-mining_staking-row">
						<div>NUM</div>
						<div>{{ item.num }}</div>
					</div>
<!--					<div class="m-mining_staking-row">-->
<!--						<div>{{ $t('SHARE') }}</div>-->
<!--						<div>{{ item.rate }}%</div>-->
<!--					</div>-->
					<div class="m-mining_staking-btn">
						<v-btn
								color="#EB6930"
								outlined
								rounded
								small
								@click="_revoke(item)"
						>{{  $t('REVOKE') }}</v-btn>
					</div>
				</div>
			</div>
		</div>

		<div class="my-stacking" v-if="!is_mobile">
			<div class="my-stacking_content">
				<div class="title">{{ $t('My Staking') }}</div>
				<table>
					<tr>
						<th></th>
						<th>{{ $t('NAME') }}</th>
						<th>{{ $t('NUM') }}</th>
<!--						<th>{{ $t('SHARE') }}</th>-->
						<th></th>
					</tr>
					<tr v-show="dividend_list.length === 0">
						<td>-</td>
						<td>-</td>
						<td>-</td>
<!--						<td>-</td>-->
						<td>-</td>
					</tr>
					<tr v-for="(item, index) in dividend_list" :key="item.key">
						<td>{{ index + 1 }}</td>
						<td>{{ cards[item.key].zhHans }}</td>
						<td>{{ item.num }}</td>
<!--						<td>{{ item.rate }}%</td>-->
						<td>
							<v-btn
									color="#2E3843"
									rounded
									@click="_revoke(item)"
							>{{ $t('REVOKE') }}</v-btn>
						</td>
					</tr>
				</table>
				<div class="no-my_stacking" v-show="dividend_list.length === 0">
					{{ $t('You can stake') }}
					<router-link class="link" to="/app/Game/MyNFT">{{ $t('combined cards') }}</router-link>
					{{ $t('and farm.') }}
				</div>
			</div>
		</div>

		<v-overlay :value="overlay">
			<v-progress-circular
					indeterminate
					size="64"
			></v-progress-circular>
		</v-overlay>
	</div>
</template>

<script>
import cards from '@/data/card'
import { mapState } from 'vuex'
import {
	dividendIncome,
	getDividendCards,
	receiveDividendIncome,
	nftDividendPoolAmount,
	nftDividendWithdraw,
	dividend_address,
} from '@/utils/cardFactory'
import eventBus from '@/utils/eventBus'

export default {
	name: 'FarmingPool',
	data () {
		return {
			dividend_address,
			balance: '0',
			btn_loading: false,
			poolAmt: '0',
			dividend_list: [],
			cards,
			revoke_loading: false,
			pc_styles: {
				height: '52px !important',
				width: '200px',
			},
			mobile_styles: {
				height: '35px !important',
				width: '130px',
			},
			overlay: false,
		}
	},
	computed: {
		...mapState(['ADDRESS']),
		is_mobile () {
			return this.$vuetify.breakpoint.mobile
		},
	},
	created () {
		if (this.ADDRESS) {
			this._poolAmount()
			this._dividendIncome()
			this._dividendCards()
		}
		eventBus.$on('connect-event', (res) => {
			this._poolAmount()
			this._dividendIncome()
			this._dividendCards()
		})
	},
	methods: {
		async _dividendIncome () {
			const res = await dividendIncome(this.ADDRESS)
			const { total } = res
			this.balance = this.web3.utils.fromWei(total, 'ether')
		},
		// 质押列表
		async _dividendCards () {
			this.dividend_list = await getDividendCards(this.ADDRESS)
		},
		// 领取
		async _receiveDividendIncome () {
			this.btn_loading = true
			try {
				await receiveDividendIncome(this.ADDRESS)
				this._dividendIncome()
				this.btn_loading = false
			} catch (err) {
				this.btn_loading = false
			}
		},
		async _poolAmount () {
			this.poolAmt = await nftDividendPoolAmount()
		},
		// 取回卡牌
		async _revoke ({ ids }) {
			this.overlay = true
			try {
				await nftDividendWithdraw(this.ADDRESS, ids)
				this._dividendCards()
				this.overlay = false
			} catch (err) {
				this.overlay = false
			}
		},
	},
}
</script>

<style lang="scss" scoped>
// pc
@media (min-width: 960px) {
	.farming-pool {
		.top-balance__box {
			.balance-value {
				img {
					width: 45px;
					margin-right: 24px;
				}
				font-size: 48px;
				line-height: 72px;
				margin-top: 20px;
			}

			.balance-title {
				font-size: 24px;
				line-height: 36px;
			}

			bottom: -123.5px;
			height: 247px;
			padding: 0 80px;
		}

		.top-left {
			a {
				font-size: 14px;
				line-height: 21px;
			}

			.subtitle {
				font-size: 14px;
				line-height: 21px;
			}

			.title {
				font-size: 24px;
				line-height: 36px;
				margin-top: 80px;
			}
		}

		.total {
			img {
				width: 65px;
				margin-right: 24px;
			}
			font-size: 64px;
			line-height: 96px;
			margin: 40px 0;
		}

		.top {
			height: 537px;
		}

		.my-stacking_content {
			padding: 90px 0;
		}
	}
}

// mobile
@media (max-width: 960px) {
	.farming-pool {
		.my-stacking_content {
			padding: 25px;
		}

		.top-balance__box {
			.balance-value {
				img {
					width: 20px;
					margin-right: 12px;
				}
				font-size: 20px;
				line-height: 30px;
				margin-top: 10px;
			}

			.balance-title {
				font-size: 14px;
				line-height: 21px;
			}

			bottom: -50px;
			height: 100px;
			padding: 0 18px;
		}

		.top-left {
			a {
				font-size: 12px;
				line-height: 18px;
			}

			.subtitle {
				font-size: 12px;
				line-height: 18px;
				max-width: 190px;
			}

			.title {
				font-size: 18px;
				line-height: 27px;
				margin-top: 4px;
			}
		}

		.total {
			img {
				width: 22px;
				margin-right: 12px;
			}
			font-size: 20px;
			line-height: 30px;
			margin: 20px 0 4px;
		}

		.top {
			padding: 25px 25px 0;
			height: 246px;
		}
	}
}

// common
.farming-pool {
	.my-stacking {
		&_content {
			table {
				td {
					padding-top: 30px;
				}

				th {
					&:first-child {
						width: 80px;
					}

					width: 190px;
					text-align: left;
					font-weight: bold;
					font-size: 14px;
					line-height: 21px;
					color: #67717A;
				}
			}

			.title {
				span {
					font-weight: bold;
					font-size: 18px;
					line-height: 27px;
					color: #EB6930;
				}

				font-weight: bold;
				font-size: 24px;
				line-height: 36px;
				margin-bottom: 50px;

				color: #E8E8E8;
			}

			display: flex;
			flex-direction: column;
			align-items: center;
		}

		.no-my_stacking {
			.link {
				color: #EB6930;
			}

			width: 338px;
			height: 51px;
			line-height: 51px;
			text-align: center;
			background: #2D3339;
			border-radius: 20px;
			margin-top: 64px;
		}

		background-color: #202831;
	}

	.top-left {
		a {
			font-weight: 500;
			font-size: 14px;
			line-height: 21px;

			text-decoration-line: underline;

			color: #EB6930;
		}

		.total {
			font-weight: bold;
			color: #EB6930;
			display: flex;
			align-items: center;
		}

		.subtitle {
			font-weight: 500;
			color: #67717A;
		}

		.title {
			font-weight: bold;
			color: #E8E8E8;
		}
	}

	.top-balance__box {
		.balance-value {
			font-weight: bold;
			color: #EB6930;
			display: flex;
			align-items: center;
		}

		.balance-title {
			font-weight: bold;
			color: #E8E8E8;
		}

		position: absolute;
		left: 0;
		right: 0;
		background: #464F57;
		border-radius: 20px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.total-stacking {
		height: 223.5px;
	}

	.top {
		.top-content {
			.top-content_img {
				.top-img {
					max-width: 508px;
					width: 100%;
				}

				flex: 1;
				text-align: right;
			}

			position: relative;
			display: flex;
			justify-content: space-between;
			max-width: 948px;
			width: 100%;
			margin: 0 auto;
		}

		display: flex;
		background: #202831;
	}
}
</style>
